import React from 'react'
import { Fade } from 'react-reveal'
import {
  Box,
  Container,
  Flex,
  Link as A,
  Text,
  Icon,
  Avatar,
  Heading
} from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  FaqHeader,
  CareerRow,
  contentContainer,
  content,
  WorkshopBackground,
  WorkshopBase,
  WorkshopDiv
} from 'components/Content'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'
import WorkshopBrochure from '../components/Workshop/Brochure'
import WorkshopRegister from '../components/Workshop/Register'

A.link = A.withComponent(Link)

const Bio = ({
  id,
  img,
  eventName,
  Faculty,
  price,
  time,
  date,
  url,
  venue,
  ...props
}) => (
  <WorkshopDiv mb={0} {...props} key={id}>
    <Box mr={[2, 3]} style={{ borderRadius: '10px' }}>
      <Avatar
        size={220}
        src={img}
        alt={title}
        style={{ borderRadius: 'inherit' }}
      />
    </Box>
    <Box style={{ lineHeight: '1.125' }}>
      <Heading.h3 fontSize={3} color="black" children={eventName} />
      <Heading.h5 fontSize={1} children={Faculty} mt={2} ml={2} />
      <Box mt={2}>
        <Flex>
          <Icon size={30} glyph="event-check" color="black" />
          <Text
            fontSize={2}
            mt={1}
            mb={0}
            ml={1}
            color="slate"
            children={date}
          />
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex ml={2}>
          &#8377;
          <Text fontSize={2} ml={1} color="slate" children={price} />
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex mt={2}>
          <Text color="slate" ml={3}>
            Venue:
          </Text>
          <Text fontSize={1} ml={2} color="slate" children={venue}></Text>
        </Flex>
      </Box>
      <Box>
        <Flex mt={4} wrap justify="center">
          <WorkshopBrochure eventName={eventName} url={url} venue={venue} />
          <WorkshopRegister
            eventName={eventName}
            faculty={Faculty}
            date={date}
          />
        </Flex>
      </Box>
    </Box>
  </WorkshopDiv>
)
const title = 'Workshops at - Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/workshops/"
    img="/cards/workshops.png"
  >
    <Nav />
    <Social />
    <WorkshopBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[2, 2]} mb={[2, 2]} mx="auto">
            <Fade bottom>Workshops</Fade>
          </Headline>
        </Container>
      </Flex>
    </WorkshopBackground>
    <Container {...contentContainer}>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="red.5">
          <strong>Latest Workshops</strong>
        </Text>
      </CareerRow>
      <WorkshopBase px={3} py={[2, 3]}>
        {data.allContentfulWorkshop.nodes.map(workshop => {
          return (
            <Bio
              key={workshop.id}
              id={workshop.id}
              img="https://rafaltrus.com/wp-content/uploads/2019/07/html-code-scaled.jpg"
              eventName={workshop.name}
              Faculty={workshop.faculty}
              date={`${workshop.startDate}--${workshop.endDate}`}
              price={workshop.fee}
              venue={workshop.venue}
              url={workshop.brochure ? workshop.brochure.file.url : ''}
            />
          )
        })}
      </WorkshopBase>
    </Container>
    <Footer />
  </Layout>
)

export const query = graphql`
  query allWorkshops {
    allContentfulWorkshop {
      nodes {
        id
        name
        faculty
        startDate(formatString: "MMM DD HH:mm")
        endDate(formatString: "MMM DD HH:mm")
        venue
        fee
        brochure {
          file {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
